import BackSvgComponent from "@assets/svg-components/back";
import { getColorStyle } from "@common/color";
import { getFirstLetterOfEachString } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import React from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
function StickyHeader(props: any) {
  const navigate = useNavigate();
  const params = useParams();
  const { handleReject, handleSubmit, submitLoading, id, route } = props;
  const getStatus = (status: string) => {
    switch (status) {
      case "unsubmitted":
        return "Unsubmitted";
      case "awaiting_approval":
        return "Submitted";
      case "approved":
        return "Approved";
      case "rejected":
        return "Rejected";
      case "reimbursed":
        return "Reimbursed";
      case "expired":
        return "Expired";
      case "archived":
        return "Archived";
      case "recalled":
        return "Recalled";
      default:
        return "";
    }
  };
  const findCurrentApproverStausEnum = (arr: any) => {
    let user = getValue(props, `formInfo.current_approver`, "");
    let app =
      getValue(arr, `length`, 0) > 0
        ? arr.filter(
            (item: object) =>
              getValue(item, `approver.id`, "") === getValue(user, `id`, "")
          )
        : [];
    return getValue(app, `length`, 0) > 0
      ? getValue(app, `[${0}].approval_status`, "")
      : getValue(props, `formInfo.current_approver.approval_status`, "");
  };

  return (
    <div className="common-sticky-header">
      <section className="inner-page-navigation-with-back-cta-buttons position-relative">
        <div className="container-fluid  d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center">
            <NavLink to={route ? `${route}` : "#"}>
              <BackSvgComponent />
            </NavLink>
            <p className="header_text"> {getValue(props, `title`, "")}</p>

            <h6
              className="ms-4"
              style={getColorStyle(
                getValue(props, `formInfo.approval_status`, "") == "unsubmitted"
                  ? "draft"
                  : getValue(props, `formInfo.approval_status`, "")
              )}
            >
              {getStatus(getValue(props, `formInfo.approval_status`, ""))}
            </h6>
          </div>
          {getValue(props, `permissions`, []).includes("update") && (
            <>
              <div className="d-flex align-items-center">
                <div className="header_square" onClick={props.handleOpenPopup}>
                  <img
                    src="/images/edit-gray.svg"
                    className="header_square_image img-fluid"
                  />
                </div>
                {getValue(props, `formInfo.approval_status`, "") ==
                  "approved" && (
                  <button
                    className="reimbursement_button mx-2 px-2"
                    onClick={() =>
                      navigate(
                        `/admin/reports/reimbursement/${getValue(
                          params,
                          `id`,
                          ""
                        )}`
                      )
                    }
                  >
                    Record Reimbursement
                  </button>
                )}
                <div className="d-flex align-items-center p-1">
                  {findCurrentApproverStausEnum(
                    getValue(props, `formInfo.approvers`, "")
                  ) == "awaiting_approval" ? (
                    <>
                      <button
                        className={`ms-2 ascent-button ascent-button--header-buttons ascent-button--green`}
                        onClick={() => handleSubmit("approve")}
                        disabled={submitLoading}
                        style={{ fontWeight: "400" }}
                      >
                        Approve
                      </button>
                      <button
                        className={`ms-2 ascent-button ascent-button--header-buttons ascent-button--red`}
                        onClick={() => handleReject("reject")}
                        disabled={submitLoading}
                        style={{ fontWeight: "400" }}
                      >
                        Reject
                      </button>
                    </>
                  ) : (
                    <div style={{ height: "48px" }}></div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </div>
  );
}

export default StickyHeader;
