import DocumentSvgComponent from "@assets/svg-components/document";
import { convertCurrentDate } from "@common/date-helpers";
import { formatText, getFirstLetterOfEachString } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import React from "react";

function TripTravelInfo(props: any) {
  return (
    <div className="trip-mandate-information">
      {getValue(props, `formInfo.created_by.email`, "") ? (
        <>
          <p className="trip-mandate-approval-info">Created By</p>

          <div className="d-flex align-items-center trip-mandate-profile-wrap">
            <div className="profile_circle bg-success">
              <h5 className="title">
                {getFirstLetterOfEachString(
                  getValue(props, `formInfo.created_by.first_name`, "")
                )}
              </h5>
            </div>
            <div>
              <h6 className={``}>
                {getValue(props, `formInfo.created_by.first_name`, "")}{" "}
                {getValue(props, `formInfo.created_by.middle_name`, "")}{" "}
                {getValue(props, `formInfo.created_by.last_name`, "")}
              </h6>
              <p className="small_text">
                {getValue(props, `formInfo.created_by.email`, "")}
              </p>
              <p className="small_text">
                Created on :{" "}
                {convertCurrentDate(getValue(props, `formInfo.created_at`, ""))}
              </p>
            </div>
          </div>
          {!getValue(props, `client`, false) ? (
            <div>
              <div
                className=" bg-white w-100 cursor-pointer trip-mandate-view-approver-flow"
                onClick={() => props.toggleTravelProfile(true)}
              >
                <h6 className="small_text__14 color_primary">
                  View Travel Profile
                </h6>
              </div>
            </div>
          ) : null}

          <div className="border_bottom trips_mandate_approver_seperator"></div>
        </>
      ) : (
        ""
      )}
      {getValue(props, `formInfo.current_approver.email`, "") ? (
        <>
          <p className="trip-mandate-approval-info">Current Approver</p>
          <div className="d-flex align-items-center trip-mandate-profile-wrap">
            <div className="profile_circle bg-warning">
              <h5 className="title">
                {getFirstLetterOfEachString(
                  getValue(props, `formInfo.current_approver.first_name`, "")
                )}
              </h5>
            </div>
            <div>
              <h6 className={``}>
                {getValue(props, `formInfo.current_approver.first_name`, "")}{" "}
                {getValue(props, `formInfo.current_approver.middle_name`, "")}{" "}
                {getValue(props, `formInfo.current_approver.last_name`, "")}
              </h6>
              <p className="small_text">
                {getValue(props, `formInfo.current_approver.email`, "")}
              </p>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {getValue(props, `formInfo.trip_approvers.length`, 0) > 0 ? (
        <div>
          <div
            className=" bg-white w-100 cursor-pointer trip-mandate-view-approver-flow"
            onClick={() => props.setShowApprovalPopup(true)}
          >
            <h6 className="small_text__14 color_primary">View approver flow</h6>
          </div>
          <div className="border_bottom trips_mandate_approver_seperator"></div>
        </div>
      ) : null}

      {getValue(props, `showTravelDocument`, false) &&
      getValue(props, `formInfo.documents.length`, 0) > 0 ? (
        <div>
          <div
            className=" bg-white mt-2 mb-3 mt-2 border-radius__5 m-auto cursor-pointer d-flex align-items-center"
            onClick={() => props.toggleDocument(true)}
          >
            <DocumentSvgComponent color={"#408dfb"} />
            <h6 className="small_text__14 p-2 color_primary">
              {getValue(props, `formInfo.documents.length`, 0)} Travel
              Document(s)
            </h6>
          </div>
          <div className="border_bottom trips_mandate_approver_seperator"></div>
        </div>
      ) : null}

      {!getValue(props, `formInfo.budget_amount`, "") ? null : (
        <div className="trip_mandate_label_text_wrap">
          <p className="small_text__14 trip_mandate_label_holder">
            Budget Amount
          </p>
          <h6 className="header_text__16 trip_mandate_value_holder">
            {getValue(props, `formInfo.claimed_currency_symbol`, "")}{" "}
            {getValue(props, `formInfo.budget_amount`, "")}
          </h6>
          <div className="border_bottom trips_mandate_approver_seperator"></div>
        </div>
      )}
      {!getValue(props, `formInfo.project.project_name`, "") ? null : (
        <div className="trip_mandate_label_text_wrap">
          <p className="small_text__14 trip_mandate_label_holder">
            Project Name
          </p>
          <h6 className="header_text__16 trip_mandate_value_holder">
            {getValue(props, `formInfo.project.project_name`, "")}
          </h6>
          <div className="border_bottom trips_mandate_approver_seperator"></div>
        </div>
      )}
      {!formatText(getValue(props, `formInfo.travel_type`, "")) ? null : (
        <div className="trip_mandate_label_text_wrap">
          <p className="small_text__14 trip_mandate_label_holder">
            Travel Type
          </p>
          <h6 className="header_text__16 trip_mandate_value_holder">
            {formatText(getValue(props, `formInfo.travel_type`, ""))}
          </h6>
          <div className="border_bottom trips_mandate_approver_seperator"></div>
        </div>
      )}
      {getValue(props, `formInfo.is_visa_required`, false) && (
        <div className="trip_mandate_label_text_wrap">
          <p className="small_text__14 trip_mandate_label_holder">
            Is Visa Required
          </p>
          <h6 className="header_text__16 trip_mandate_value_holder">
            {getValue(props, `formInfo.is_visa_required`, false) ? "Yes" : "No"}
          </h6>
          <div className="border_bottom trips_mandate_approver_seperator"></div>
        </div>
      )}
      {/* {getValue(props, `formInfo.is_billable`, false) && ( */}
      <div className="trip_mandate_label_text_wrap">
        <p className="small_text__14 trip_mandate_label_holder">Is Billable</p>
        <h6 className="header_text__16 trip_mandate_value_holder">
          {getValue(props, `formInfo.is_billable`, false) ? "Yes" : "No"}
        </h6>
        <div className="border_bottom trips_mandate_approver_seperator"></div>
      </div>
      {/* )} */}
      {getValue(props, `formInfo.created_by.policy.policy_name`, "") ? (
        <div className="trip_mandate_label_text_wrap">
          <p className="small_text__14 trip_mandate_label_holder">Policy</p>
          {/* <div className="d-flex align-items-center mt-1"> */}
          {/* <OrganisationsSvgComponent size={22} color={"#408dfb"} /> */}
          <p className="header_text__16 trip_mandate_value_holder">
            {getValue(props, `formInfo.created_by.policy.policy_name`, "")}
          </p>
          {/* </div> */}
        </div>
      ) : null}
    </div>
  );
}

export default TripTravelInfo;
