import RemoveCircleSvgComponent from "@assets/svg-components/remove-circle";
import {
  handleSelectArrayChange,
  handleSelectArrayChangeByKeyValue,
  handleSelectArrayChangeStaticValue,
} from "@common/handleChange";
import { handleTextArrayChange } from "@common/handleChange";
import { formatBoolean, formatText } from "@common/text-helpers";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import { getValue } from "@utils/lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Input } from "reactstrap";

function Step2(props: any) {
  const { request, setRequest, condtionalFields } = props;
  const [selectedField, setSelectedField] = useState({});
  const type = [
    { value: "AND", label: "AND" },
    { value: "OR", label: "OR" },
  ];
  const type1 = [{ value: "WHEN", label: "When" }];
  /* -------------------------------------------------------------------------- */
  /*                             Add/Remove section                             */
  /* -------------------------------------------------------------------------- */
  const addCriteria = () => {
    if (getValue(request, `approval_config_conditions.length`, 0) < 10) {
      let obj = {
        field_name: "",
        operation: "",
        field_value: "",
        criteria_pattern:
          getValue(request, `approval_config_conditions.length`, 0) === 0
            ? "WHEN"
            : "AND",
      };
      request.approval_config_conditions.push(obj);
      setRequest({ ...request });
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const getExpressions = (name: string) => {
    let filteredOptions =
      getValue(condtionalFields, `length`, 0) > 0
        ? condtionalFields.filter(
            (item: object) => getValue(item, `value`, "") === name
          )
        : [];
    let selectedOptions = getValue(filteredOptions, `[${0}].operators`, []);
    let options = convertObjecttoJsonArray(selectedOptions);
    return getValue(options, `length`, 0) > 0 ? options : [];
  };
  const convertObjecttoJsonArray = (obj: any) => {
    const jsonArray = [];
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const newObject = {
          key: key,
          value: obj[key],
          label: formatText(obj[key]),
        };
        jsonArray.push(newObject);
      }
    }

    const finalJsonArrayString = JSON.stringify(jsonArray);
    return JSON.parse(finalJsonArrayString);
  };
  const getSelectedOption = (name: string) => {
    let filteredOptions =
      getValue(condtionalFields, `length`, 0) > 0
        ? condtionalFields.filter(
            (item: object) => getValue(item, `value`, "") === name
          )
        : [];
    return getValue(filteredOptions, `[${0}].type`, "");
  };
  const getLookupKey = (name: string) => {
    switch (name) {
      case "POLICY":
        return "policies";
      case "DEPARTMENT":
        return "departments";
      case "PROJECT":
        return "projects";
      case "EXPENSE_TYPE":
        return "expense_type";
      case "CUSTOMER":
        return "customers";
      case "DESIGNATION":
        return "designation";
      case "FLIGHT_CLASS":
        return null;
      default:
        return null;
    }
  };
  const getFilteredArray = (array: any) => {
    if (getValue(array, `length`, 0) > 0) {
      let selectedArray = array.filter(
        (item: object) => getValue(item, `action`, "") !== "REMOVE"
      );
      return selectedArray;
    } else {
      return [];
    }
  };
  const getFilteredArrayLength = (array: any) => {
    if (getValue(array, `length`, 0) > 0) {
      let selectedArray = array.filter(
        (item: object) => getValue(item, `action`, "") !== "REMOVE"
      );
      return getValue(selectedArray, `length`, 0);
    } else {
      return 0;
    }
  };
  const generateExpressions = (array: any) => {
    let arr = getFilteredArray(array).map((item: any, index: number) => ({
      ...item,
      index: index,
    }));
    const logicalOperators: any = {
      AND: " AND ",
      OR: " OR ",
    };
    const stack: any = [];
    let expression = "";
    for (const obj of arr) {
      const name = obj.criteria_pattern;
      if (logicalOperators[name]) {
        let obje = { operator: logicalOperators[name], index: obj.index };
        stack.unshift(obje);
      } else {
        if (stack.length > 0) {
          const { operator, index } = stack.pop();
          const prevExpression = expression;
          expression = `( ${prevExpression} ${operator}${index + 1} )`;
        } else {
          expression += `${obj.index + 1}`;
        }
      }
    }
    while (stack.length > 0) {
      const { operator, index } = stack.pop();
      const prevExpression = expression;
      expression = `( ${prevExpression} ${operator}${index + 1} )`;
    }

    if (getValue(request, `criteria_pattern`, "") !== expression) {
      setRequest({
        ...request,
        criteria_pattern: expression,
      });
    }
    return expression;
  };
  const getCriteria = () => {
    return generateExpressions(
      getValue(request, `approval_config_conditions`, [])
    );
  };
  let criteria = useMemo(() => getCriteria(), [
    // request,
    getValue(request, `approval_config_conditions.length`, []),
  ]);

  const handleRemoveCondition = (index: number) => {
    let array = getFilteredArray(
      getValue(request, `approval_config_conditions`, [])
    );
    let filtered = array.filter((item: object, ind: number) => ind !== index);
    setRequest({
      ...request,
      approval_config_conditions: filtered,
    });
  };
  return (
    <div className="custom-approval-step2">
      <div className="custom-approval-header-wrap">
        <h6 className="header_text__20_0_weigth">
          Define the criteria ( if any )
        </h6>
        <p className="small_text">
          Trigger the Approval flow when the following conditions are satisfied.
        </p>
      </div>
      <div>
        {getFilteredArrayLength(
          getValue(request, `approval_config_conditions`, [])
        ) > 0
          ? getFilteredArray(
              getValue(request, `approval_config_conditions`, [])
            ).map((item: object, index: number) => {
              return (
                <div className="d-flex align-items-center mb-16 custom-approval-criteria-wrrapper">
                  <h6 className="header_text">{index + 1}</h6>
                  <div
                    className="d-flex align-items-center mx-3"
                    style={{ flexWrap: "wrap" }}
                  >
                    <div className="mb-1 custom-approval-criteria-first-field">
                      <SingleSelectSearchDropdown
                        data={index === 0 ? type1 : type}
                        label="label"
                        headerWidth={"230px"}
                        optionsWidth={"230px"}
                        customStyle
                        placeholder={"Select"}
                        value={getValue(item, `criteria_pattern`, "")}
                        selectKey={"value"}
                        onChange={(e: object) =>
                          handleSelectArrayChange(
                            index,
                            e,
                            "criteria_pattern",
                            "approval_config_conditions",
                            setRequest
                          )
                        }
                        disabled={index === 0 ? true : false}
                      />
                    </div>
                    {/* <div className="ms-3"></div> */}
                    <div className="mb-1">
                      <SingleSelectSearchDropdown
                        data={condtionalFields}
                        label="label"
                        headerWidth={"200px"}
                        optionsWidth={"300px"}
                        customStyle
                        placeholder={"Select Field Name"}
                        value={getValue(item, `field_name`, "")}
                        selectKey={"value"}
                        onChange={(e: object) => {
                          handleSelectArrayChange(
                            index,
                            e,
                            "field_name",
                            "approval_config_conditions",
                            setRequest
                          );
                          handleSelectArrayChange(
                            index,
                            "",
                            "operation",
                            "approval_config_conditions",
                            setRequest
                          );
                        }}
                      />
                    </div>
                    {getValue(item, `field_name`, "") && (
                      <>
                        {/* <div className="ms-3"></div> */}
                        <div className=" mb-1">
                          <SingleSelectSearchDropdown
                            data={getExpressions(
                              getValue(item, `field_name`, "")
                            )}
                            label="label"
                            headerWidth={"200px"}
                            optionsWidth={"300px"}
                            customStyle
                            placeholder={"Select Operation"}
                            value={getValue(item, `operation`, "")}
                            selectKey={"value"}
                            onChange={(e: object) =>
                              handleSelectArrayChange(
                                index,
                                e,
                                "operation",
                                "approval_config_conditions",
                                setRequest
                              )
                            }
                          />
                        </div>
                      </>
                    )}
                    {getValue(item, `operation`, "") && (
                      <>
                        {/* <div className="ms-3"></div> */}
                        <div className="mb-1 ">
                          {getSelectedOption(
                            getValue(item, `field_name`, "")
                          ) === "number" ? (
                            <div className="">
                              <Input
                                type="number"
                                className="form-control"
                                style={{ height: "44px", width: "230px" }}
                                placeholder="Enter here"
                                name={"field_value"}
                                onChange={(e) =>
                                  handleTextArrayChange(
                                    index,
                                    e,
                                    "approval_config_conditions",
                                    setRequest
                                  )
                                }
                                value={getValue(item, `field_value`, "")}
                              />
                            </div>
                          ) : null}

                          {getSelectedOption(
                            getValue(item, `field_name`, "")
                          ) === "dropdown" ||
                          getSelectedOption(
                            getValue(item, `field_name`, "")
                          ) === "search_text" ? (
                            <div>
                              <SingleSelectSearchDropdown
                                label="label"
                                headerWidth={"285px"}
                                optionsWidth={"285px"}
                                customStyle
                                lookup_api={getLookupKey(
                                  getValue(item, `field_name`, "")
                                )}
                                lookup_id={getValue(item, `field_value_id`, "")}
                                selectKey={"workflow_name"}
                                value={getValue(item, `field_value`, "")}
                                onChange={(e: any) => {
                                  handleSelectArrayChangeByKeyValue(
                                    index,
                                    e,
                                    "field_value",
                                    "approval_config_conditions",
                                    "workflow_name",
                                    setRequest
                                  );
                                  handleSelectArrayChangeByKeyValue(
                                    index,
                                    e,
                                    "field_value_id",
                                    "approval_config_conditions",
                                    "id",
                                    setRequest
                                  );
                                }}
                              />
                            </div>
                          ) : null}
                          {getSelectedOption(
                            getValue(item, `field_name`, "")
                          ) === "boolean" ? (
                            <div>
                              <SingleSelectSearchDropdown
                                label="label"
                                headerWidth={"300px"}
                                optionsWidth={"300px"}
                                customStyle
                                data={[
                                  { value: true, label: "Yes" },
                                  { value: false, label: "No" },
                                ]}
                                value={formatBoolean(
                                  getValue(item, `field_value`, "")
                                )}
                                selectKey={"value"}
                                onChange={(e: object) =>
                                  handleSelectArrayChange(
                                    index,
                                    e,
                                    "field_value",
                                    "approval_config_conditions",
                                    setRequest
                                  )
                                }
                              />
                            </div>
                          ) : null}
                        </div>
                      </>
                    )}
                  </div>
                  {/* <div className="ms-3"></div> */}
                  {getFilteredArrayLength(
                    getValue(request, `approval_config_conditions`, [])
                  ) -
                    1 ===
                    index && (
                    <div
                      className="cursor-pointer"
                      // onClick={() => removeCriteria(index)}
                      onClick={() =>
                        getValue(item, `id`, "")
                          ? handleSelectArrayChangeStaticValue(
                              index,
                              "approval_config_conditions",
                              "action",
                              "REMOVE",
                              setRequest
                            )
                          : handleRemoveCondition(index)
                      }
                    >
                      <RemoveCircleSvgComponent color={"#fe7070"} />
                    </div>
                  )}
                </div>
              );
            })
          : null}
      </div>
      {/* {criteria && ( */}
      <div
        className="p-3 d-flex align-items-center"
        style={{ background: "#f8f8fd" }}
      >
        <h6 className="header_text">Criteria Pattern:</h6>
        <p className="desc_text ms-4">{getCriteria()}</p>
      </div>
      {/* )} */}

      {getValue(request, `approval_config_conditions.length`, 0) < 10 && (
        <div className=" d-flex justify-content-between align-items-center">
          <div
            className="custom_field_button_container me-2 mt-3"
            onClick={() => addCriteria()}
          >
            <img
              className="custom_field_button_container_image"
              src={`/images/add_blue.svg`}
            />
            <h6 className="custom_field_button_container_text">Add Criteria</h6>
          </div>
        </div>
      )}
      <div className="border-bottom approval-detail-border-bottom"></div>
    </div>
  );
}

export default Step2;
