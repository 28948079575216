import { convertCurrentDate } from "@common/date-helpers";
import { formatText } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import React from "react";

function ExpenseDetailSection(props: any) {
  const { formInfo } = props;
  return (
    <div>
      <div className="expense-detail-wrapper_report_infosection d-flex">
        <div className="expense-detail-wrapper_report d-flex align-items-center">
          <div className="">
            <h5 className="header_text">
              {convertCurrentDate(getValue(formInfo, `expense_date`, ""))}
            </h5>
            <p className="desc_text">Expense Date</p>
          </div>
          {/* <div className="vertical_line"></div> */}
        </div>
        <div className="expense-detail-wrapper_report d-flex align-items-center">
          <div className="">
            <h5 className="header_text">
              {getValue(formInfo, `expense_type.expense_type_name`, "")}
            </h5>
            <p className="desc_text">Type</p>
          </div>
          {/* <div className="vertical_line"></div> */}
        </div>
        <div className="expense-detail-wrapper_report d-flex align-items-center">
          <div className="">
            <h5 className="header_text">
              {" "}
              {formatText(getValue(formInfo, `paid_through.account_name`, ""))}
            </h5>
            <p className="desc_text">Paid Through</p>
          </div>
          {/* <div className="vertical_line"></div> */}
        </div>
        {getValue(formInfo, `expense_entry_mileage.distance`, "") && (
          <>
            <div className="expense-detail-wrapper_report d-flex align-items-center">
              <div className="">
                <h5 className="header_text">
                  {getValue(formInfo, `expense_entry_mileage.distance`, "")}{" "}
                  {getValue(formInfo, `expense_entry_mileage.mileage_unit`, "")}
                </h5>
                <p className="desc_text">Distance</p>
              </div>
              {/* <div className="vertical_line"></div> */}
            </div>
            <div className="expense-detail-wrapper_report d-flex align-items-center">
              <div className="">
                <h5 className="header_text">
                  {getValue(
                    formInfo,
                    `expense_entry_mileage.vehicle.vehicle_name`,
                    ""
                  )}
                </h5>
                <p className="desc_text">Vehicle Type</p>
              </div>
              {/* <div className="vertical_line"></div> */}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ExpenseDetailSection;
