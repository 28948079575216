import DashboardExpenseSvgComponent from "@assets/svg-components/dashboard/expense";
import DashboardReportSvgComponent from "@assets/svg-components/dashboard/report";
import TripsSvgComponent from "@assets/svg-components/dashboard/trips";
import UploadSvgComponent from "@assets/svg-components/dashboard/upload";
import { useNavigate } from "react-router-dom";
import AddLightGreySvgComponent from "@assets/svg-components/dashboard/add-lightgrey";

function DashboardQuickAddSection(props: any) {
  const navigate = useNavigate();
  const handleNavigate = (url: string) => {
    navigate(url);
  };
  return (
    <div className="">
      <h6 className="header_text__20 mt-34">Quick Add</h6>
      <div className="d-flex dashboard_quickadd_section">
        <div className="dashboard_quickadd_section__green   d-flex justify-content-center position-relative">
          <div
            className="flex-container"
            onClick={() => handleNavigate(`/client/expenses/add`)}
          >
            <div className="dashbaord-wrapper_info-section2_right_body_circle">
              <div className="mb-2">
                <DashboardExpenseSvgComponent />
              </div>
            </div>
            <div>
              <p className="header_text__14">Create Expense</p>
            </div>
          </div>
          <div className="add-icon-holder"> <AddLightGreySvgComponent size={24} /> </div>
        </div>
        <div className="dashboard_quickadd_section__orange  d-flex justify-content-center position-relative">
          <div
            className="flex-container"
            onClick={() => handleNavigate(`/client/reports`)}
          >
            <div className="dashbaord-wrapper_info-section2_right_body_circle">
              <div className="mb-2">
                <img
                  src={require(`../../../../assets/svg-components/dashboard/report-1.png`)}
                />
              </div>
            </div>
            <div>
              <p className="header_text__14">Create Report</p>
            </div>
          </div>
          <div className="add-icon-holder"> <AddLightGreySvgComponent size={24} /> </div>
        </div>
        <div className="dashboard_quickadd_section__blue  d-flex justify-content-center position-relative">
          {/* <div
            className="flex-container"
            onClick={() => handleNavigate(`/client/expenses/add`)}
          >
            <div className="dashbaord-wrapper_info-section2_right_body_circle">
              <div className="mt-2">
                <UploadSvgComponent size={30} color={"#276EF1"} />
              </div>
            </div>
            <div>
              <p className="header_text__14">Drag & Drop Receipts here</p>
              <p className="desc_text text-center">OR</p>
              <p className="desc_text color_primary_main text-center">
                Click here to attach
              </p>
            </div>
          </div> */}
          <div
            className="flex-container"
            onClick={() => handleNavigate(`/client/trips/add`)}
          >
            <div className="dashbaord-wrapper_info-section2_right_body_circle">
              <div className="mb-2">
                <TripsSvgComponent size={36} color={"#276EF1"} />
              </div>
            </div>
            <div>
              <p className="header_text__14 mt-1">Create Trip</p>
            </div>
          </div>
          <div className="add-icon-holder"> <AddLightGreySvgComponent size={24} /> </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardQuickAddSection;
