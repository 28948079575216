import DownloadSvgComponent from "@assets/svg-components/download";
import { currentDate } from "@common/date-helpers";
import { handleDownloadOnClick } from "@common/downloadfile";
import {
  exportDepartments,
  exportExpenseCategory,
  exportExpenseType,
  exportProjects,
  exportUsers,
} from "@services/import.service";
import { downloadTravelDocumentExcel } from "@services/settings.service";
import { getValue } from "@utils/lodash";
import React, { useState } from "react";

function CommonDownloadSection(props: any) {
  const [downloadLoading, setDownloadLoading] = useState(false);
  const handleDownload = async () => {
    try {
      setDownloadLoading(true);
      let resp;
      //users
      if (getValue(props, `download_type`, "") === "USER") {
        resp = await exportUsers("");
      }
      //travel-document
      if (getValue(props, `download_type`, "") === "TRAVEL_DOCUMENT") {
        resp = await downloadTravelDocumentExcel("");
      }
      //departments
      if (getValue(props, `download_type`, "") === "DEPARTMENT") {
        resp = await exportDepartments("");
      }
      //projects
      if (getValue(props, `download_type`, "") === "PROJECT") {
        resp = await exportProjects("");
      }
      //expense type
      if (getValue(props, `download_type`, "") === "EXPENSE_TYPE") {
        resp = await exportExpenseType("");
      }
      //expense category
      if (getValue(props, `download_type`, "") === "EXPENSE_CATEGORY") {
        resp = await exportExpenseCategory("");
      }
      if (resp) {
        handleDownloadOnClick(
          getValue(resp, `data.downloadUrl`, ""),
          `report_${currentDate()}`
        );
        setDownloadLoading(false);
      } else {
        setDownloadLoading(false);
      }
    } catch (error) {
      setDownloadLoading(false);
    }
  };
  const dummy = () => {};
  return (
    <div className="cursor-pointer filterbar-icon-button-holder">
      {/* ms-2 */}
      <div onClick={() => (downloadLoading ? dummy() : handleDownload())}>
        <div className="text-center">
        {/* ms-4 */}
          <DownloadSvgComponent size={24} color="#5f6368" />
        </div>
        <p className="small_text__12">
          {downloadLoading ? "Please wait..." : "DOWNLOAD"}
        </p>
      </div>
    </div>
  );
}

export default CommonDownloadSection;
