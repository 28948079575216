import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllExpenseEntries = (queryRequest: string) =>
  get(`${config.API_URL}/expense-entry?${queryRequest}`);

export const getSpecificExpenseEntry = (id: string) =>
  get(`${config.API_URL}/expense-entry/${id}`);

export const createExpenseEntry = (payload: string) =>
  post(`${config.API_URL}/expense-entry`, payload);

export const updateExpenseEntry = (id: string, payload: string) =>
  patch(`${config.API_URL}/expense-entry/${id}`, payload);

export const deleteSpecificExpenseEntry = (id: string) =>
  Delete(`${config.API_URL}/expense-entry/${id}`);

export const getAllExpenseEntryForm = (queryRequest: string) =>
  get(`${config.API_URL}/expense-entry/form?${queryRequest}`);

export const getAllExpenseEntryFormEdit = (id: string) =>
  get(`${config.API_URL}/expense-entry/${id}/form-edit`);

export const getAllExpenseEntryFormEditExpenseType = (
  id: string,
  queryRequest: string
) => get(`${config.API_URL}/expense-entry/${id}/form-edit?${queryRequest}`);

//expense-entry upload

export const uploadExpenseEntryReceipts = (id: string, payload: object) =>
  patch(`${config.API_URL}/expense-entry/${id}/upload/receipts`, payload);

export const deleteExpenseEntryReceipts = (id: string, receiptId: string) =>
  Delete(
    `${config.API_URL}/expense-entry/${id}/receipt?receipt_id=${receiptId}`
  );

//comments

export const getAllExpenseEntryComments = (id: string, queryRequest: string) =>
  get(`${config.API_URL}/expense-entry/${id}/comments?${queryRequest}`);

export const createExpenseEntryComment = (id: string, payload: object) =>
  post(`${config.API_URL}/expense-entry/${id}/comments?`, payload);

export const deleteExpenseEntryComment = (id: string) =>
  Delete(`${config.API_URL}/expense-entry/comments/${id}`);

export const getExpenseFormByExpenseType = (queryRequest: string) =>
  get(`${config.API_URL}/expense-entry/form?${queryRequest}`);

export const updateExpenseAmount = (id: string, payload: object) =>
  patch(`${config.API_URL}/expense-entry/${id}/amount`, payload);