import React from "react";
import InputField from "@common/InputRuleFeild";
import { getValue } from "@utils/lodash";
// import "./right-popup.scss";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
function ExpenseTypePopup(props: any) {
  const {
    showUpdatePopup,
    setShowUpdatePopup,
    request,
    handleChangeText,
    submitLoading,
    handleSubmit,
    editId,
    setRequest,
  } = props;
  return (
    <div
      className={`compelte-task-wrapper ${
        showUpdatePopup ? "compelte-task-wrapper--active" : ""
      }`}
      style={{ width: "40%" }}
    >
      <div className="compelte-task-wrapper__header d-flex align-items-center justify-content-between">
        <h6 className="compelte-task-wrapper__title">Expense Types</h6>
        <img
          src="/images/close-white.svg"
          className="img-fluid navigation-bar-wrapper__close-btn"
          onClick={() => {
            setShowUpdatePopup(false);
            props.handleReset();
          }}
        />
      </div>
      <div className="main_popup_container">
        <div className="expense-active-category-add-form-wrap">
          <div className="user-details-wrapper__form-group form-group">
            <label className="checkpoint-active-details__label form-label-error">
              Expense Type Name <span>*</span>{" "}
            </label>
            <InputField
              inputType="TEXT"
              placeholder="Enter Account Name"
              className="form-control"
              name="expense_type_name"
              id="expense_type_name"
              label="Expense Type Name"
              value={getValue(request, `expense_type_name`, "")}
              onChange={(e: any) => handleChangeText(e)}
              // validator={simpleValidator}
            />
          </div>
          <div className="user-details-wrapper__form-group form-group">
            <label className="checkpoint-active-details__label form-label-error">
              Expense Type Code <span>*</span>{" "}
            </label>
            <InputField
              inputType="TEXT"
              placeholder="Enter Account Code"
              className="form-control"
              name="expense_type_code"
              id="expense_type_code"
              label="Expense Type Code"
              value={getValue(request, `expense_type_code`, "")}
              onChange={(e: any) => handleChangeText(e)}
              // validator={simpleValidator}
            />
          </div>
          <div className="user-details-wrapper__form-group form-group">
            <label className="checkpoint-active-details__label form-label-error">
              Description <span>*</span>{" "}
            </label>
            <InputField
              inputType="TEXTAREA"
              placeholder="Enter Description"
              className="form-control"
              name="description"
              id="description"
              label="Description"
              value={getValue(request, `description`, "")}
              onChange={(e: any) => handleChangeText(e)}
              // validator={simpleValidator}
            />
          </div>
          <div className="user-details-wrapper__form-group form-group">
            <label className="form-label form-label-error">
              Expense Category<span>*</span>{" "}
            </label>
            <SingleSelectSearchDropdown
              label={"label"}
              selectKey={"id"}
              name="Type"
              value={getValue(request, `expense_category`, "")}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  expense_category: getValue(e, `id`, ""),
                })
              }
              placeholder={"Choose Type"}
              width={"100%"}
              lookup_api={"expense_category"}
              // validator={simpleValidator}
            />
          </div>
          
          <div className="user-details-wrapper__form-group form-group">
            <label className="form-label form-label-error">
              Policy<span>*</span>{" "}
            </label>
            {/* <SingleSelectSearchDropdown
            label={"label"}
            selectKey={"id"}
            name="Policy"
            value={getValue(request, `policy_id`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                policy_id: getValue(e, `id`, ""),
              })
            }
            placeholder={"Choose Policy"}
            width={"100%"}
            lookup_api={"policies"}
            // validator={simpleValidator}
          /> */}

            <InputField
              inputType="INPUT_REACT_SELECT"
              isMulti
              placeholder="Select Policy"
              options={getValue(props, `policyList`, [])}
              onChange={(e: any) =>
                props.setRequest({
                  ...request,
                  policy_ids: e,
                })
              }
              value={getValue(request, `policy_ids`, [])}
            />
          </div>
          
          <div className="user-details-wrapper__form-group form-group">
            <label>Mileage</label>
            <div className="choicelist-wrapper custom-switch-wrapper">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={getValue(request, `is_mileage`, false)}
                  onChange={() =>
                    props.setRequest({
                      ...request,
                      is_mileage: !getValue(request, `is_mileage`, false),
                    })
                  }
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed_moda_footer_wrapper">
        <div style={{ marginRight: "20px" }}>
          <button
            className="ascent-button ascent-button--header-buttons ascent-button--primary "
            onClick={() => {
              setShowUpdatePopup(false);
              props.handleReset();
            }}
          >
            Cancel
          </button>
          <button
            className="ascent-button ascent-button--header-buttons ascent-button--secondary ms-3"
            onClick={() => handleSubmit()}
            disabled={submitLoading}
          >
            {submitLoading ? "Please wait..." : editId ? "Update" : "Create"}
          </button>
        </div>
      </div>

      {/* <div style={{ float: "right", marginRight: "8%" }}>
        <button
          className="ascent-button ascent-button--primary"
          onClick={() => setShowUpdatePopup(false)}
        >
          Cancel
        </button>
        <button
          className="ascent-button ascent-button--secondary"
          onClick={() => handleSubmit()}
          disabled={submitLoading}
        >
          {submitLoading ? "Please wait..." : editId ? "Update" : "Create"}
        </button>
      </div> */}
    </div>
  );
}

export default ExpenseTypePopup;
